import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataTransportService } from './services/data-transport.service';
import { ChartComponent } from "ng-apexcharts";
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexTheme, ApexTitleSubtitle } from "ng-apexcharts";
import {} from 'googlemaps';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  theme: ApexTheme;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-external-transport',
  templateUrl: './external-transport.component.html',
  styleUrls: ['./external-transport.component.css'],
})
export class ExternalTransportComponent implements OnInit {
mostrar = true;
isLinear = true;
geoformGroup: FormGroup;

map: google.maps.Map;
center: google.maps.LatLngLiteral;

source: google.maps.LatLngLiteral;
destination: google.maps.LatLngLiteral;

options: google.maps.MapOptions = {
  mapTypeId: google.maps.MapTypeId.ROADMAP,
  scrollwheel: true,
  disableDefaultUI: false,
  disableDoubleClickZoom: true,
  zoom:12
}

ds: google.maps.DirectionsService;
dr: google.maps.DirectionsRenderer;

optionsSearch = {
  types: [],
  componentRestrictions: { country: 'DO' }
};

@ViewChild("chart") chart: ChartComponent;
public chartOptions: Partial<ChartOptions>;

variableCostDetail:any;
fixedCostDetail:any;

insuranceDetail:number;
licenseDetail:number;
tiresDetail:number;
fuelDetail:number;
maintenanceDetail:number;
parkingDetail:number;
oilDetail:number;
batteryDetail:number;
sueldoDetail:number;
peajeDetail:number;

categoryAPI: { id:number, name:string };
category:string;

truckAPI:any;
truck:string;

fuelAPI: { id:number, ident:string, name:string, price:number };
fuel:any;

SemiTrailersAPI: { id:number, axles:number, tires:number };
containersAPI: { id:number, containerName: string, emptyTons:number, maxTons: number };
semitrailerSelected:any;
containerSelected:any;

oil = [];
tires = [];
tiresCalc:any;
peajes: number = 0;
sueldo: number = 0;
licenseAPI = [];
license:any;
battery = [];
insurance = [];
distanceKm:string;
tonsLoad:number;
tonsTruck:number; 
totalCost:number;
goingCost:number;
backCost:number;
estimatedTime:string;

constructor(private TransportService:DataTransportService, private _formBuilder: FormBuilder) {

  this.chartOptions = {
    series: [],
      chart: {
        width: "100%",
        type: "pie"
      },
      labels: [],
      theme: {
        monochrome: {
          enabled: true,
          color: '#EA5D0E'
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              fontsize: '11',
              position: "top"
            }
          }
        }
      ]
    };
  };

ngOnInit(): void {

  /* Form group for step form */
  this.geoformGroup = this._formBuilder.group({
    origin: ['', Validators.required],
    destination: ['', Validators.required]
  });

  /* --------------------- Map ------------------------ */

  this.ds = new google.maps.DirectionsService();
  this.dr = new google.maps.DirectionsRenderer({
    map: null,
    suppressMarkers: true
  });

  navigator.geolocation.getCurrentPosition(position => {

    this.source = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };
      
    this.map = new google.maps.Map(document.getElementById('map-canvas'), {
      ...this.options,
      center: this.source
    });

  });

  /* ------------------------------------------------------------------------ */

  this.TransportService.getCategories().subscribe(
    (res) => this.categoryAPI = res,
    (err) => console.log(err)
  );

  this.TransportService.getFuel().subscribe(
    (res) => this.fuelAPI = res,
    (err) => console.log(err)
  );

  this.TransportService.getSemiTrailers().subscribe(
    (res) => this.SemiTrailersAPI = res,
    (err) => console.log(err)
  );



};

licenseSelected(){
  
  this.TransportService.getLicense().subscribe(
    (res) => {
      let data = res;
      const dataFiltered = data.filter( (license) => license.year == this.license );
      this.licenseAPI = dataFiltered;
      },
      (err) => console.log(err)
    );

};

semitrailer(){
  
  this.TransportService.getContainers().subscribe(
    (res) => {
      let data = res;
      const dataFiltered = data.filter( (container) => container.chassis == this.semitrailerSelected.chassis);
      this.containersAPI = dataFiltered;
    },
    (err) => console.log(err)
  );
  
};

truckSelected(){

  this.TransportService.getTires().subscribe(
    (res) => {
      let data = res;
      const dataFiltered = data.filter( (tires) => tires.ident == this.truck );
      this.tires = dataFiltered;
    },
      (err) => console.log(err)
  );

  this.TransportService.getBattery().subscribe(
    (res) => {
      let data = res;
      const dataFiltered = data.filter( (battery) => battery.ident == this.truck );
      this.battery = dataFiltered;
    },
      (err) => console.log(err)
  );

  this.TransportService.getInsurance().subscribe(
    (res) => {
      let data = res;
      const dataFiltered = data.filter( (insurance) => insurance.ident == this.truck );
      this.insurance = dataFiltered;
    },
      (err) => console.log(err)
  );

};

categorySelected() {

  this.TransportService.getTruck().subscribe(
    (res) => {
      let data = res;
      const dataFiltered = data.filter( (truck) => truck.category == this.category );
      this.truckAPI = dataFiltered;
    },
      (err) => console.log(err)
  );

  this.TransportService.getOil().subscribe(
    (res) => {
      let data = res;
      const dataFiltered = data.filter( (oil) => oil.category == this.category );
      this.oil = dataFiltered;
    },
      (err) => console.log(err)
  );

}
 
searchPlaces() {
 
  this.map = new google.maps.Map(document.getElementById('map-canvas'), {
    ...this.options,
    center: this.source
  });

  const geocoder = new google.maps.Geocoder();

  const origin = (document.getElementById('origin') as HTMLInputElement).value;
  const destination = (document.getElementById('destination') as HTMLInputElement).value;
  this.mostrar = false;
  const originMarker = () => {
    return new Promise( (resolve, reject) => {
      resolve(
        geocoder.geocode({ address: origin, 'componentRestrictions': { 'country': 'DO' } }, (results:any, status) => {

          const marker = new google.maps.Marker({
            position: { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() },
            label: 'A',
            draggable: true,
            map: this.map
            
            
          });
    
          this.source = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
    
        })
      )
    });
  };

  const destinationMarker = () => {
    return(
      geocoder.geocode({ address: destination, 'componentRestrictions': {'country':'DO'}}, (results:any, status) => {
  
        const marker = new google.maps.Marker({
          position: {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()},
          label:'B',
          draggable:true,
          map: this.map
        });
  
        this.destination = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()};
      })
    )
  };
  
  const setMarkers = async () => {
    await originMarker();
    await destinationMarker();
    await this.setRoutePolyline();

  };

  setMarkers();
};

setRoutePolyline() {

  let request = {
    origin: this.source,
    destination: this.destination,
    travelMode: google.maps.TravelMode.DRIVING
  };

  this.ds.route(request, (response, status) => {

    this.estimatedTime = response.routes[0].legs[0].duration.text;
    this.distanceKm = response.routes[0].legs[0].distance.text;

    this.dr.setOptions({
      suppressPolylines: false,
      map: this.map
    });

    if(status == google.maps.DirectionsStatus.OK){
      this.dr.setDirections(response);
    };

  });

};

estimate(){

  /* Costos fijos */
  let insurance = (this.insurance[0].price / 12) / 25;
  this.license == "1950" ? this.license = 5 : this.license = 10;
  let maintenance = this.truckAPI.filter( (truck) => truck.ident == this.truck );
  maintenance =  maintenance[0].maintenanceCost;
  let parkingCost = this.truckAPI.filter( (truck) => truck.ident == this.truck );
  parkingCost = parkingCost[0].parkingCost;
  this.tonsTruck = this.truckAPI.filter( (truck) => truck.ident == this.truck );
  
  if(this.semitrailerSelected == undefined || this.containerSelected == undefined){
    this.tonsTruck = this.tonsTruck[0].tons;
  } else {
    this.tonsTruck = this.tonsTruck[0].tons + this.semitrailerSelected.emptyTons + this.containerSelected.emptyTons;
  };

  /* ---------------------------------------------------------- */

  /* Costos variables */
  let glX1km = this.truckAPI.filter( ( truck ) => truck.ident == this.truck );
  let fuel:number = glX1km[0].glX1km * this.fuel;

  if( this.semitrailerSelected == undefined ) {
    this.tiresCalc = (this.tires[0].price / this.tires[0].usefulLife) * this.tires[0].tires;
  }else{
    this.tiresCalc = (this.tires[0].price / this.tires[0].usefulLife) * (this.tires[0].tires + this.semitrailerSelected.tires);
  }

  let oilEngine:number = (this.oil[0].quartsOilQuantity * this.oil[0].pricePerQuarts) / this.oil[0].performance;
  let oilTransmition:number = (this.oil[1].quartsOilQuantity * this.oil[1].pricePerQuarts) / this.oil[1].performance;
  let oilHydraulic:number = (this.oil[2].quartsOilQuantity * this.oil[2].pricePerQuarts) / this.oil[2].performance;
  let oilTrain:number = (this.oil[3].quartsOilQuantity * this.oil[3].pricePerQuarts) / this.oil[3].performance;
  let oil = oilEngine + oilTransmition + oilHydraulic + oilTrain;
  let battery:number = (this.battery[0].totalPrice / this.battery[0].performance ) / 25;

  /* ---------------------------------------------------------- */
  this.distanceKm = this.distanceKm.split(',').join('.').split('km')[0];

  // ============================================ //
  this.insuranceDetail = (this.insurance[0].price / 12) / 25;
  this.licenseDetail = this.license;
  this.parkingDetail = parkingCost;

  this.tiresDetail = (this.tiresCalc * parseInt(this.distanceKm)) * 2;
  this.fuelDetail = fuel * parseInt(this.distanceKm);
  this.maintenanceDetail = maintenance;
  this.oilDetail = oil * parseInt(this.distanceKm);
  this.batteryDetail = battery;
  this.sueldoDetail = this.sueldo / 23.83;
  this.peajeDetail = this.peajes;

  const going = () => {

    // Gastos fijos
    let gastosFijos = insurance + this.license + parkingCost;
    gastosFijos = gastosFijos * (this.tonsLoad + this.tonsTruck);
    Math.round(gastosFijos);

    // Gastos variables
    let gastosVariables = this.tiresCalc + fuel + oil + battery + maintenance;
    gastosVariables = gastosVariables * parseInt(this.distanceKm);
    Math.round(gastosVariables);

    // For details screen
    this.fixedCostDetail = gastosFijos;
    this.variableCostDetail = gastosVariables;
    // ===============================================
    
    let sueldo = this.sueldo / 23.83;

    let costoIDA = gastosVariables + gastosFijos + this.peajes + sueldo;

    return costoIDA;

  };


  const back = () => {
    
    // Gastos variables
    let gastosVariables = this.tiresCalc + fuel + oil + battery + maintenance;
    gastosVariables = gastosVariables * parseInt(this.distanceKm);
    Math.round(gastosVariables);
      
    let costoVUELTA = gastosVariables;

    return costoVUELTA;

  };

  this.goingCost = going();
  this.backCost = back();

  this.totalCost = this.goingCost + this.backCost;

  this.chartOptions.series = [this.variableCostDetail , this.fixedCostDetail];
  this.chartOptions.labels = ["Gastos variables","Gatos fijos"];

};

print(){
  window.print();
}

}
