import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard.component';
import { ExternalTransportComponent } from './external-transport/external-transport.component';

import { NgApexchartsModule } from 'ng-apexcharts';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    DashboardComponent, 
    ExternalTransportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule, 
    MatInputModule, 
    MatStepperModule,
    MatSelectModule,
    NgApexchartsModule,
    GooglePlaceModule
  ],
})
export class DashboardModule {}
