<div class="dashboard-container">Referenciales
    
    <div class="navbar-container">
        <div class="navbar-logo">
            <img src="../../../../assets/img/dasboard-logo.png" alt="Logo de DIGESETT e Intrant">
        </div>
    </div>
    <div class="title-container">
        <h1>Costos Referenciales de Transportes De Mercancias </h1>
        <h2>(CORTRAM v2.0)</h2>
    </div>
    <div class="component-container">
        <router-outlet></router-outlet>
    </div>
    
    <div class="footer-container">
        <p>©2021 INTRANT - Todos los derechos reservados</p>
    </div>
</div>