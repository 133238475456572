<div class="form-container">
    <mat-vertical-stepper linear #stepper>
        
        <div class="map-global-container">

            <mat-step [stepControl]="geoformGroup" label="Ruta">

                <div class="route-adress-container">
                    <form [formGroup]="geoformGroup">
                        <span>Origen:</span>
                        <input formControlName="origin" ngx-google-places-autocomplete [options]='optionsSearch' type="text" id="origin" placeholder="Origen del viaje">
                        <span>Destino:</span>
                        <input formControlName="destination" ngx-google-places-autocomplete [options]='optionsSearch' type="text" id="destination" placeholder="Destino del viaje">
                                                
                        <button (click)= searchPlaces() id="calcula">Calcular<i class="fas fa-route"></i></button>
                        
                        
                        
                      
<!--                <script src="./services/index.js"></script> -->
                        
                    </form>
                </div>

                <div class="map-container">
                    <div class="map-frame">
                       <div #map id="map-canvas"></div>
                    </div> 
                </div>
                <div class="button-container">
                     <button type="submit" class="button button-next" matStepperNext [hidden]="mostrar">Siguiente<i class="fas fa-arrow-right"></i></button>
                </div> 

                

            </mat-step>
        </div>
         
        <mat-step label="Datos del viaje">
            
            <div class="form-container-2">

                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Categoria de camión</mat-label>
                    <mat-select [(ngModel)]="category" (ngModelChange)="categorySelected()">
                        <mat-option *ngFor="let categories of categoryAPI" [value]="categories.name">
                            {{ categories.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Tipo de camión</mat-label>
                    <mat-select [(ngModel)]="truck" (ngModelChange)="truckSelected()">
                        <mat-option *ngFor="let truck of truckAPI" [value]="truck.ident">
                            {{ truck.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="truck == 'T-01' || truck == 'T-02'" class="example-full-width" appearance="fill">
                    <mat-label>Semiremolque</mat-label>
                    <mat-select [(ngModel)]="semitrailerSelected" (ngModelChange)="semitrailer()">
                        <mat-option *ngFor="let truck of SemiTrailersAPI" [value]="truck">
                            Chassis de {{ truck.chassis }} de {{ truck.axles }} eje
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="truck == 'T-01' || truck == 'T-02'" class="example-full-width" appearance="fill">
                    <mat-label>Contenedor</mat-label>
                    <mat-select [(ngModel)]="containerSelected">
                        <mat-option *ngFor="let truck of containersAPI" [value]="truck">
                            {{ truck.containerName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Combustible</mat-label>
                    <mat-select [(ngModel)]="fuel">
                        <mat-option *ngFor="let fuel of fuelAPI" [value]="fuel.price">
                            {{ fuel.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Año del vehículo</mat-label>
                    <mat-select [(ngModel)]="license" (ngModelChange)="licenseSelected()">
                        <mat-option [value]="2015">Igual o mayor a 2015</mat-option>
                        <mat-option [value]="1950">Menor a 2015</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <mat-label>Toneladas a cargar</mat-label>
                    <input matInput type="number" [(ngModel)]="tonsLoad">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Gastos de peajes</mat-label>
                    <input matInput type="number" [(ngModel)]="peajes">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Sueldo mensual del chofer</mat-label>
                    <input matInput type="number" [(ngModel)]="sueldo">
                </mat-form-field>

            </div>

            <div class="button-container">
                <button type="submit" class="button button-back" matStepperPrevious><i class="fas fa-arrow-left"></i>Atras</button>
                <button (click)="estimate()" type="submit" class="button button-next"  matStepperNext>Siguiente<i class="fas fa-arrow-right"></i></button>
            </div>
              
        </mat-step>

        <mat-step id="print" label="Resultado">

            <div class="results-container">

                <h1>Resultados</h1>

                <h3>Resumen de costos operativos</h3>

                <div class="results-panels">

                    <div class="bigpanels">
                        <h3>Información del viaje</h3>
                        <h5>Distancia del recorrido: {{ distanceKm }}Km</h5>
                        <h5>Toneladas a cargar: {{ tonsLoad }}Tn</h5>
                        <h5>Tiempo estimado: {{ estimatedTime }}</h5>
                    </div>

                    <div class="bigpanels xl">

                        <h3>Gráfico de gastos distribuidos</h3>

                        <apx-chart class="chart"     
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [labels]="chartOptions.labels"
                        [title]="chartOptions.title"
                        [theme]="chartOptions.theme"
                        [responsive]="chartOptions.responsive"
                        ></apx-chart>
                    </div>

                    <div class="bigpanels">
                        <h3>Costos Asociados:</h3>
<!--                       <h5>Seguro: RD {{ insuranceDetail | currency}}</h5> -->
                        <h5>Seguro</h5>
<!--                        <h5>Placa: RD {{ licenseDetail | currency}} </h5> -->                      
                        <h5>Placa</h5>
<!--                        <h5>Neumaticos: RD {{ tiresDetail | currency}}</h5>--> 
                        <h5>Neumaticos</h5>
<!--                        <h5>Combustible: RD {{ fuelDetail | currency}}</h5>-->
                        <h5>Combustible</h5>
<!--                        <h5>Mantenimiento: RD {{ maintenanceDetail | currency}}</h5>-->
                        <h5>Mantenimiento</h5>
<!--                        <h5>Parqueo: RD {{ parkingDetail | currency}}</h5>-->
                        <h5>Parqueo</h5>
<!--                        <h5>Aceite: RD {{ oilDetail | currency}}</h5>-->
                        <h5>Aceite</h5>
<!--                        <h5>Bateria: RD {{ batteryDetail | currency}}</h5>-->
                        <h5>Bateria</h5>
<!--                        <h5>Chofer: RD {{ sueldoDetail | currency}}</h5>-->
                        <h5>Chofer</h5>
<!--                        <h5>Peajes: RD {{ peajeDetail | currency}}</h5>-->
                        <h5>Peajes</h5>
                        <h5>Despreciacion</h5>
                        <h5>Otros</h5>
                    </div>

                </div>
                <div class="results-panels-r">
                    <div class="panel-r">
                        <h2>Nota: Estos Calculos no son una tarifa sino una estimacion de costos operativos asociados del transporte de carga</h2>
                        <h2>(Reglameto Nacional de Transporte de Carga, Art.81, ley 63-17).</h2>
                    </div>
                    
                </div>    
                <div class="results-panels">

                    <div class="panel">
                        <h2>Costo de ida: RD{{ goingCost | currency}}</h2>
                    </div>

                    <div class="panel">
                        <h2>Costo del regreso: RD{{ backCost | currency}}</h2>
                    </div>

                    <div class="panel panel-finalPrice">
                        <h2>Costo total: RD{{ totalCost | currency}}</h2>
                    </div>

                </div>

            </div>
     
            <div class="button-container">

                <button type="submit" class="button button-back" matStepperPrevious><i class="fas fa-arrow-left"></i>Atras</button>
                
                <button (click)="print()" class="button button-next"><i class="fas fa-print"></i>Imprimir</button>
            
            </div>
            
        </mat-step>

    </mat-vertical-stepper>
</div>
