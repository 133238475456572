import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataTransportService {

  constructor(public http: HttpClient) { }

  getTruck():Observable<any> {
    return this.http.get(`${ environment.api}camion`);
  };

  getCategories():Observable<any> {
    return this.http.get(`${ environment.api}categoria`);
  }

  getFuel():Observable<any> {
    return this.http.get(`${ environment.api}rendimientocombustible`);
  }

  getOil():Observable<any> {
    return this.http.get(`${ environment.api}rendimientoaceite`);
  }

  getTires():Observable<any> {
    return this.http.get(`${ environment.api}neumatico`);
  }

  getLicense():Observable<any> {
    return this.http.get(`${ environment.api}placa`);
  }

  getBattery():Observable<any> {
    return this.http.get(`${ environment.api}bateria`);
  }

  getInsurance():Observable<any> {
    return this.http.get(`${ environment.api}seguro`);
  }

  getContainers():Observable<any> {
    return this.http.get(`${ environment.api}camion/container`);
  }

  getSemiTrailers():Observable<any> {
    return this.http.get(`${ environment.api}camion/semitrailer`);
  }

}
